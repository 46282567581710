import React, { useEffect, useState } from "react";
import "react-intl-tel-input/dist/main.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Logouser from "../../assets/images/log-user.png";
import { userDetailsActionFn } from "../../redux/actions/userDetailsAction";
import Header from "../common/header";
import moment from "moment";


const UserSettings = (props) => {
  const dispatch = useDispatch();

  const [name, setName] = useState("")
  const [userDetails, setUserDetails] = useState({})


  useEffect(() => {
    getUserDetails()
  },[]);

  const getUserDetails = async () =>{
    try {
      const response = await dispatch(userDetailsActionFn())
      const userDataResponse = response?.payload
      setUserDetails(userDataResponse?.data)
      if(userDataResponse?.data?.user?.name){
        setName(userDataResponse?.data.user.name)
      }
      else(
        setName(userDataResponse?.data?.user?.companyName)
      )

    } catch (err) {
      console.error(err)
    }
  }


  return (
    <>
      <Header />
      <section class="dash-wrap">
        <div class="settings-block small">
          <div class="img-indv">
            <img src={userDetails?.user?.avtarImage} alt="" />
          </div>
          <div class="">
            <div class="indv-block">
              <p>
                Name <span>{name ? name : ""} </span>
              </p>
            </div>
            <div class="indv-block">
              <p>
                Phone number <span>{userDetails?.user?.phone}</span>
              </p>
            </div>
            <div class="indv-block">
              <p>
                Email address <span>{userDetails?.user?.email}</span>
              </p>
            </div>
            <div class="indv-block">
              <p>
                Account type{" "}
                <span>
                  {/* {UserDetail?.user?.accountType?.map((useracc) => {
                    return <p>{useracc}</p>;
                  })} */}
                  <p>{userDetails?.user?.currentAccountType}</p>
                </span>
              </p>
            </div>
          </div>
          <div class="indv-status">
            <div class="indv-status-main">
              <h6>KYC Status</h6>
              <p>
                updated {moment(userDetails?.user?.updatedAt).format("Y-MM-DD")}
              </p>
              <Link class="btn green-btn sm-btn" to="#">
                {userDetails?.user?.kycStatus}
               
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserSettings;
